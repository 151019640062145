import styled, { keyframes } from "styled-components";
import { layout, flexbox } from "styled-system";

const linear = keyframes`
0% {
    opacity: 0;
    -webkit-transform: translateX(-40%) scale(.3, 1);
    transform: translateX(-40%) scale(.3, 1);
  }
  
  10% {
    opacity: 1;
    -webkit-transform: translateX(-30%) scale(.3, 1);
    transform: translateX(-30%) scale(.3, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(50%) scale(0.1, 1);
    transform: translateX(50%) scale(0.1, 1);
  }
`;

export default styled.div`
  background: ${({ theme }) => theme.colors.neutralGray};
  height: 100%;
  transition: all 0.2s linear;
  animation: ${linear} 1.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: ${props => `${props.animationDelay || "0"}ms`};
  ${layout};
  ${flexbox};
`;
