import ClassCard from "app/components/ClassCard";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import { LibrarySelectContentContainer } from "services/typewriter/segment";
import LoadingContentCard from "./LoadingContentCard";

const FeaturedClass = ({
  classData,
  thumbnailWidth,
  selectedFrom,
  requested,
}) => {
  if (!requested) {
    return <LoadingContentCard />;
  }

  if (!classData) {
    return null;
  }

  return (
    <ClassCard
      data={classData}
      thumbnailWidth={thumbnailWidth}
      selectedFrom={selectedFrom}
      container={LibrarySelectContentContainer.FeaturedClass}
      scaleSize={1.05}
    />
  );
};

const mapStateToProps = ({ firebase }, { classId }) => ({
  requested: firebase.requested[`classes_details/${classId}`],
  classData: getVal(firebase, `data/classes_details/${classId}`),
});

FeaturedClass.defaultProps = {
  classData: null,
  thumbnailWidth: undefined,
  selectedFrom: undefined,
  requested: false,
};

FeaturedClass.propTypes = {
  classData: PropTypes.shape({}),
  thumbnailWidth: PropTypes.string,
  selectedFrom: PropTypes.string,
  requested: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect(props => [`classes_details/${props.classId}`])
)(FeaturedClass);
