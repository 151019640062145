import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LoaderLinear from "app/components/Loader/LoaderLinear";
import { CARD_RATIO } from "app/components/ClassCard/Card/styles";

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(143, 143, 143, 0.5);
  padding-top: ${CARD_RATIO * 100}%;
`;

const IntrinsicItem = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ hasShadow }) =>
    hasShadow &&
    `
    box-shadow: 10px 10px 1px #b3b7b7,20px 20px 1px #dee2e2
`}
`;

const Thumbnail = styled.div`
  display: flex;
  flex: 1 1 auto;
  background: ${({ theme }) => theme.colors.lightGrey};
`;

const LoadingContentCard = ({ margin, hasShadow, index }) => (
  <Wrapper margin={margin}>
    <IntrinsicItem hasShadow={hasShadow}>
      <Thumbnail>
        <LoaderLinear
          display="flex"
          flex="1 1 auto"
          animationDelay={index * 400}
        />
      </Thumbnail>
    </IntrinsicItem>
  </Wrapper>
);

LoadingContentCard.defaultProps = {
  index: 0,
  hasShadow: false,
  margin: "0 20px 20px 0",
};

LoadingContentCard.propTypes = {
  index: PropTypes.number,
  hasShadow: PropTypes.bool,
  margin: PropTypes.string,
};

export default LoadingContentCard;
